import { converse, _converse, api } from '@converse/headless/core';

converse.plugins.add('close-mobile-chatboxes', {
    initialize: function() {
        var _converse = this._converse;

        _converse.on('connected', function () {
            api.listen.on("chatBoxesFetched", function () {
                if (window.innerWidth <= 768) {
                    _converse.chatboxes.models.forEach((element, id) => {
                        if (id != 0) {
                            element.save({'hidden': true});
                        } else {
                            element.save({'closed': true});
                        }
                    });
                }
            });
        });
    }
});