import { _converse, converse } from "headless/core";

export const setBackgrondToGroupChat = (roomJid) => {
    _converse.api.rooms.get(roomJid).then(val => {
        const { u } = converse.env;

        u.safeSave(val, {
            'hidden': true,
            'minimized': true,
            'time_minimized': new Date().toISOString(),
            'show_on_trimmed_chats': false
        });
        return;
    });
}

export const openViewToGroupChat = (roomJid) => {
    _converse.api.rooms.get(roomJid).then(val => {
        const { u } = converse.env;

        u.safeSave(val, {
            'hidden': false,
            'minimized': false,
            'time_minimized': new Date().toISOString(),
            'show_on_trimmed_chats': false
        });
        return;
    });
}

export const closeAllGroupChats = () => {
    _converse.api.rooms.get().then(val => {
        val.forEach(element => {
            element.close(); 
        });
    });
} 

export const closeGroupChats = (roomJid) => {
    _converse.api.rooms.get(roomJid).then(val => {
        if (val) 
            val.close();
    });
}

export const reduceDmChat = (chat) => {
    const { u } = converse.env;

    chat.setChatState(_converse.INACTIVE);
    u.safeSave(chat, {
        'hidden': true,
        'minimized': true,
        'time_minimized': new Date().toISOString()
    });
}