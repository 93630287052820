import { converse, _converse, api } from '@converse/headless/core';
import { closeAllGroupChats, closeGroupChats, openViewToGroupChat, reduceDmChat, setBackgrondToGroupChat } from './utils';

converse.plugins.add('open-chat-buttons', {
    initialize: function () {
        var _converse = this._converse;
        _converse.on('connected', function () {
            Promise.all([
                _converse.api.waitUntil('rosterContactsFetched'),
                _converse.api.waitUntil('chatBoxesFetched')
            ]).then(function () {
                OpenChat.isLoaded = true;
                OpenChat.isWaiting && OpenChat.waitConnected();
            });
        });
    }
});

export class OpenChat {
    static isLoaded = false;
    static isWaiting = false;

    static roomToBeOpenededOnConnected = [];

    static openDmChat(jid, name) {
        if (this.isLoaded) {
            _converse.api.contacts.add(jid, name);
            _converse.api.chats.open(jid).then();
        }
    }

    static openGroupChat (roomJid, action = 'enter', background = true) {
        if (!this.isLoaded) return;

        switch (action) {
            case 'enter':
                _converse.api.rooms.open(roomJid).then(_ => background ? setBackgrondToGroupChat(roomJid) : openViewToGroupChat(roomJid));
                return;
            case 'exit':
                if (roomJid !== "*")
                    closeGroupChats(roomJid);
                else
                    closeAllGroupChats();
                return;
            default:
                console.warn("Unrecognized action: ", action);
                return;
        }
    }

    static async closeDmChat(jid, reduce = false) {
        const val = await _converse.api.chats.get(jid);
        if (!val)
            return;

        if (reduce) {
            reduceDmChat(val);
        } else {
            val.close()
        }
    }

    static waitConnected() {
        if (!this.isLoaded) {
            this.isWaiting = true;
        } else {
            this.roomToBeOpenededOnConnected.forEach((room) =>
                this.openGroupChat(room.jid, room.action, room.background)
            );
        }
    }

    static addGroupChat(jid, action = 'enter', background = true) {
        this.roomToBeOpenededOnConnected.push({
            jid,
            action,
            background
        })
    }
}
