import { converse, _converse, api } from '@converse/headless/core';

converse.plugins.add('clean-history-24h', {
    initialize: function() {
        var _converse = this._converse;

        api.settings.extend({
            // default: 1 hour
            'clean_history_24h_timeout_in_ms': 60 * 60 * 1000,
            // default 24 hours
            'clean_message_older_than_in_ms': 24 * 60 * 60 * 1000 
        });

        _converse.on('connected', function () {
            setInterval(HistoryCleaner.cleanLast24HourHistory, api.settings.get('clean_history_24h_timeout_in_ms'));
        });
    }
});

export class HistoryCleaner {
    static isOlderThan24Hours (time) {
        const now = new Date().getTime();
        const date = new Date(time).getTime();
        const dayInMS = api.settings.get('clean_message_older_than_in_ms');
        const differenceInMs = now - date;

        return differenceInMs >= dayInMS;
    }

    static async cleanLast24HourHistory ()
    {
        console.log("Start deleting old messages");
        _converse.chatboxes.models.forEach((chatbox, id) =>
        {
            if (id != 0)
            {
                chatbox.clearUnreadMsgCounter(false);
                chatbox.messages.forEach((message) =>
                {
                    const timestamp = message.attributes.time;
                    const message_id = message.attributes.id;

                    if (timestamp && message_id)
                    {
                        // If the message is older than 24 hours, ignore it
                        if (HistoryCleaner.isOlderThan24Hours(timestamp))
                        {
                            console.log("h24: message with id:" + message_id + "will not be shown (too old).", message, "CHATBOX: ", chatbox);
                            chatbox.messages.remove(message_id);
                        } else {
                            chatbox.handleUnreadMessage(message);
                        }
                    }
                });

                chatbox.trigger("add", chatbox);
                chatbox.trigger("change:num_unread", chatbox);
                chatbox.trigger("change:num_unread_general");
            }
        });
        console.log("End deleting old messages");
    }
}