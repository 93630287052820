import tplTrimmedChat from "../templates/trimmed_chat.js";
import { CustomElement } from 'shared/components/element.js';
import { api, _converse } from "@converse/headless/core";
import { maximize, minimize } from  '../utils.js';

export default class MinimizedChat extends CustomElement {

    static get properties () {
        return {
            model: { type: Object },
            title: { type: String },
            type: { type: String },
            num_unread: { type: Number },
            show_on_trimmed_chats: { type: Boolean },
        }
    }

    render () {
        const data = {
            'close': ev => this.close(ev),
            'restore': ev => this.restore(ev),
            'minimize': ev => this.minimize(ev),
            'num_unread': this.num_unread,
            'title': this.title,
            'type': this.type,
            'show_on_trimmed_chats': true
        };
        return tplTrimmedChat(data);
    }

    minimize(ev) {
        ev?.preventDefault();
        const isPrivateChat = this.model.get('type') === _converse.PRIVATE_CHAT_TYPE;

        if (isPrivateChat) {
            this.model.close();
            return;
        }

        maximize(this.model);
        minimize(ev, this.model, false);
    }

    close (ev) {
        ev?.preventDefault();
        this.model.close();
    }

    restore (ev) {
        ev?.preventDefault();
        maximize(this.model);
    }
}

api.elements.define('converse-minimized-chat', MinimizedChat);
