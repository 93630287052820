import { converse, api } from '@converse/headless/core';
import log from 'headless/log';

converse.plugins.add('update-vcard-cache', {
    initialize: function()
    {
        console.log('update-vcard-cache: initialize registered');
        var _converse = this._converse;

        _converse.on('connected', () => {
            console.log('update-vcard-cache: addHandler any message headline');

            Promise.all([
                _converse.api.waitUntil('rosterContactsFetched'),
                _converse.api.waitUntil('chatBoxesFetched')
            ]).then(function ()
            {
                try {
                    const bareJid = _converse.connection.jid.split('/')[0];

                    console.log('update-vcard-cache: after login - updating VCARD for jid: ', bareJid);
        
                    _converse.api.vcard.get(bareJid).then(
                        (value) => {
                            _converse.api.vcard.update(bareJid, true);
                        }
                    );
                } catch (error) {
                    console.error('update-vcard-cache: error updating VCARD for jid: ' + _converse.connection.jid, error);
                }    
            });

            _converse.connection.addHandler((message) => {

                try {
                    //console.log('update-vcard-cache: message headline received: ', message);

                    const bodyElement = message.querySelector('message > body');
                    const bodyText = bodyElement ? bodyElement.textContent.trim() : null;
                    //console.log('update-vcard-cache: message headline bodyText: ', bodyText);

                    if (bodyText && bodyText.startsWith('VGH_SERVICE_'))
                    {
                        //bodyText includes 'user.vcard: updated': update the vcard
                        if (bodyText == 'VGH_SERVICE_USER_VCARD_UPDATED') {
                            const jid = message.getAttribute('from');
                            console.log('update-vcard-cache: message VGH_SERVICE_USER_VCARD_UPDATED received - updating VCARD for jid: ', jid);
                            _converse.api.vcard.update(jid, true);
                        }
                    }
                } catch (error) {
                    console.error('update-vcard-cache: error processing message headline: ', error);
                }

                return true;
            }, null, 'message', 'headline', null, null);
        });

        _converse.on('contactPresenceChanged', async function (data) {
            try {
                if (data.presence.changed.show == 'online') {
                    console.log('update-vcard-cache: contactPrecenceChanged to online - updating VCARD for jid: ', data.attributes.jid);
                    await _converse.api.vcard.update(data.attributes.jid, true);
                }
            } catch (error) {
                console.error(`update-vcard-cache: error updating VCARD for jid: ${data.attributes.jid}:`, error);
            }
        });
    }
})

